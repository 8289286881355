.main-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto 11fr;
  grid-template-areas: 'sideBar-col content-col';
}

.sidebar-holder {
  grid-area: sideBar-col;
  height: 100%;
  border-right: 1px solid #0d6efd;
}

.header-row-holder {
  width: 100%;
  grid-area: header;
  border-bottom: 1px solid #0d6efd;
}

.content-row-holder {
  width: 100%;
  grid-area: content;
}
