.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.096);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: cadetblue;
  font-size: 2rem;
  .gwt {
    display: flex;
    align-items: center;
  }
  .loginPrompt {
    font-size: 16px;
  }
}
