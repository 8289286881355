.container {
  display: inline-flex;
  align-items: center;
}

.badge {
  background: #da4d0c;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  margin-left: 8px;
}
